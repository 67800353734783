





































import {axiosDelete, axiosGet, axiosPost} from '@/utils/axiosWrapper';
import Vue from 'vue';

export default Vue.extend({
  name: "Signup",
  data() {
    return {
      userdata: {
        name: "",
        email: "",
        password: "",
        aboutMe: ""
      },
      password2: "",
      agb: false,

      formErrors: "",
      query: false
    }
  },
  methods: {
    async signupSubmit() {
      this.query = true
      let doesUserExist = (await axiosGet(`/users/email/${this.userdata.email}`))
      console.log(doesUserExist)

      if (doesUserExist.data.data) {
        console.warn("User already exists, overwriting old user if he didnt set a password.")
        if (doesUserExist.data.pwdHash) {
          this.formErrors = 'User already exists. Please enter a different E-Mail or try to sign in.'
        } else {
          await axiosDelete(`/users/${doesUserExist.data._id}`)
          this.doSignUp()
        }
      } else {
        this.doSignUp()
      }

      this.query = false
    },
    doSignUp: function () {
      axiosPost(`/users/`,
          {
            name: this.userdata.name,
            email: this.userdata.email,
            password: this.userdata.password,
          }
      ).then(() => {
        this.$router.push('/login')
      }).catch(err => {
        console.warn(err)
        alert("Your Sign-up was not successful. If this error continues, feel free to write us an E-Mail at help@kaiser.fyi")
      })
    },
    checkForm: function () {
      this.formErrors = "";
      if (!this.agb)
        this.formErrors = "Please accept the privacy statement and the terms of service.";
      if (this.userdata.email === "") {
        this.formErrors = 'Email required.';
      } else if (!this.validEmail(this.userdata.email)) {
        this.formErrors = 'Valid email required.';
      }
      this.userdata.email = this.userdata.email.toLowerCase()
      if (this.formErrors === "" && this.userdata.password === this.password2) {
        this.signupSubmit()
      }
    },
    validEmail: function (email: string) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
});
